.header-link_header-link__svBPT {
  position: relative;
  background-repeat: no-repeat;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  -webkit-text-decoration-line: none;
          text-decoration-line: none;
}

@media (min-width: 1024px) {

  .header-link_header-link__svBPT {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

.header-link_header-link__svBPT:focus {
  outline-style: solid;
  outline-width: 2px;
  outline-color: #8E3A80;
}

.header-link_header-link-active__q8Ki1::before {
  position: absolute;
  bottom: 0px;
  left: 1.5rem;
  height: 0.125rem;
  width: 2.5rem;
  background-image: url('/images/horizontal-dots-green.svg');
  background-size: cover;
  background-position: center;
  content: var(--tw-content);
  background-repeat: no-repeat;
}

.header-link_header-link-active__q8Ki1:before {
  content: "";
  left: calc(50% - 20px);
}

.header_fixed-nav__BtJPr {

    position: fixed;

    top: 0px;

    z-index: 20;

    width: 100%;

    background-color: rgb(204 174 208 / 0.6);

    transition-property: color, background-color, border-color, fill, stroke, -webkit-text-decoration-color;

    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;

    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, -webkit-text-decoration-color;

    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);

    transition-duration: 150ms
}

.header_fixed-nav__BtJPr:focus-within {

    --tw-bg-opacity: 1;

    background-color: rgb(255 255 255 / var(--tw-bg-opacity))
}

.header_fixed-nav__BtJPr:hover {

    --tw-bg-opacity: 1;

    background-color: rgb(255 255 255 / var(--tw-bg-opacity))
}

.header_fixed-nav-stuck__Q_uLH {

    --tw-bg-opacity: 1;

    background-color: rgb(255 255 255 / var(--tw-bg-opacity));

    opacity: 1
}

